<template>
  <div class="dynamic">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel height="80px" direction="vertical" arrow="never" indicator-position="none" :interval="5000">
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="shanpin">产品中心</div>
            <div class="active" @click="zixun">资讯动态</div>
            <div @click="zhaoxian">招贤纳士</div>
            <div @click="yuedong">走进跃动</div>
            <div @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div class="daohang">
          <div class="dhval">
            <div @click="shouye">首页</div>
            <img src="../assets/img/dhicon.png">
            <div @click="zixun">资讯动态</div>
            <img src="../assets/img/dhicon.png">
            <div>文章详情</div>
          </div>
        </div>
        <div class="dynamicmain">
          <div class="maintitle">{{data.newsTitle}}</div>
          <div class="maindate">
            <div class="dates">
              <img src="../assets/img/date.png" />
              <div>{{data.timeDate}}</div>
            </div>
            <div>浏览次数：{{data.newsTimes}}</div>
          </div>
          <div class="bor"></div>
          <!-- 导读 -->
          <div class="daodu">
            导读：{{data.guide}}
          </div>
          <!-- 绑定内容 -->
          <div class="info" v-html="data.newContentHtml"></div>
          <div class="card">
            <div class="cardinfo" :class="JSON.stringify(nexts)=='{}'?'':'right'" @click="previous(pre.Id)" v-show="JSON.stringify(pre)!='{}'">
              <div>上一篇</div>
              <div>{{pre.newsTitle}}</div>
              <div>
                <img src="../assets/img/date.png" />
                <div>{{pre.timeDate}}</div>
              </div>
            </div>
            <div class="cardinfo" @click="next(nexts.Id)" v-show="JSON.stringify(nexts)!='{}'">
              <div>下一篇</div>
              <div>{{nexts.newsTitle}}</div>
              <div>
                <img src="../assets/img/date.png" />
                <div>{{nexts.timeDate}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Dynamic",
  components: { footerComponents },
  data() {
    return {
      id:null,
      data:{},//主体内容
      pre:{},//上一篇
      nexts:{},//下一篇
    };
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian(){
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong(){
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi(){
      this.$router.push("/contactUs");
    },
    //上一篇
    previous(id){
      this.$router.push({name:"Dynamic",query: {id:id}});
    },
    //下一篇
    next(id){
      this.$router.push({name:"Dynamic",query: {id:id}});
    }
  },
  created() {
    //获取上个页面跳转传递过来的id
    this.id=this.$route.query.id
  },
  mounted() {
    if(/Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)) {
      //移动设备打开的网页，缩放页面尺寸
      /*
      var clientWidth = document.body.clientWidth;
      var rate = clientWidth / 1920;
      */
      var rate = 0.1;
      var meta = document.getElementsByTagName('meta');
      meta['viewport'].setAttribute('content', 'width=device-width,initial-scale='+rate);
    }
    this.$http.get(`/api/details?Id=${this.id}`).then(res=>{
      let a= JSON.parse(res.data)
      if(a[0].code=='success'){
        console.log(a[0])
        this.data=a[0].data[0]
        //下一篇不为空就保存
        if(a[0].next!=null){
          this.nexts=a[0].next[0]
        }
        //上一篇不为空就保存
        if(a[0].pre!=null){
          this.pre=a[0].pre[0]
        }
      }
    })
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1480px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  .daohang{
    height: 60px;
    background: #F4F5F9;
    .dhval{
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      >div{
        height: 20px;
        font-size: 14px;
        font-family: Source Han Sans SC-Regular, Source Han Sans SC;
        font-weight: 400;
        color: #2D394B;
      }
      div:nth-child(1),div:nth-child(3){
        &:hover{
          cursor:pointer;//鼠标变小手
        }
      }
      >img{
        width: 18px;
        height: 18px;
        margin: 0 8px;
      }
    }
  }
  .dynamicmain {
    width: 880px;
    margin: 0 auto;
    padding-top: 60px;
    .maintitle {
      font-size: 40px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 60px;
      color: #161e2a;
    }
    .maindate {
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 14px;
      color: #8c97a8;
      .dates {
        display: flex;
        align-items: center;
        margin-right: 60px;
        img {
          margin-right: 8px;
        }
      }
    }
    .bor {
      margin-top: 20px;
      width: 880px;
      height: 0px;
      border: 1px solid #ebebeb;
    }
    .daodu {
      margin-top: 30px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 28px;
      color: #8c97a8;
    }
    .info {
      margin-top: 24px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 28px;
      color: #626778;
      /deep/img{
        width: 100% !important;
        height: auto !important;
      }
    }
    .card {
      margin: 60px 0;
      display: flex;
      align-items: center;
      .cardinfo {
        padding: 30px;
        box-sizing: border-box;
        width: 430px;
        height: 202px;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 20px rgba(90, 97, 107, 0.08);
        border-radius: 12px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        &:hover {
          cursor: pointer;
          > div:nth-child(2) {
            color: #004097;
          }
        }
        > div:nth-child(1) {
          width: 54px;
          height: 27px;
          font-size: 18px;
          line-height: 18px;
          color: #161e2a;
          position: relative;
          
          &::after {
            position: absolute;
            bottom: -4px;
            content: "";
            left: 50%;
            transform: translate(-50%);
            width: 50px;
            height: 4px;
            background: #004097;
          }
        }
        > div:nth-child(2) {
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: #161e2a;
        }
        > div:nth-child(3) {
          margin-top: 15px;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 14px;
          color: #8c97a8;
          img {
            margin-right: 8px;
          }
        }
      }
      .right{
        margin-right: 20px;
      }
    }
  }
}
</style>
